import axios from 'axios';
import { useCallback } from 'react';

export const useButtonClickLogger = () => {
  const isValidKebabCase = (str) => {
    //Must be all lower case separated by dash (-)
    //Example: test-button-id
    const kebabCaseRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;

    return kebabCaseRegex.test(str);
  };

  const kebabToTitleCase = (str) => {
    str = str.toString().trim().toLowerCase();
    return str.split('-').reduce((result, word) => {
      return result + (result ? ' ' : '') + word.charAt(0).toUpperCase() + word.slice(1);
    }, '');
  };

  const handleButtonClickLogger = useCallback(async (event, page, mid, pmid) => {
    let target = event.currentTarget;

    if (target && target.id) {
      //Validate ID
      if (!isValidKebabCase(target.id)) {
        throw 'ID must be defined in kebab case';
      }
      const buttonName = kebabToTitleCase(target.id);

      //Build payload
      const payload = {
        page: page,
        buttonId: target.id,
        buttonName: buttonName,
        memberId: mid,
        moduleId: pmid,
      };

      const response = await axios.post(`/utils/postButtonClickLogger`, payload);
      return response.data;
    } else {
      //Throw error
      let errorMessage = 'Missing event target or target id for button logger';
      throw errorMessage;
    }
  }, []);

  return { handleButtonClickLogger };
};
