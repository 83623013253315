import { isObject } from './helper';

export const eventNames = {
  shareProgramDetails: 'share_program_details',
  shareTeam: 'share_team',
  shareECert: 'share_ecert',
  searchAvailablePrograms: 'search_available_programs',
  searchCompletedPrograms: 'search_completed_programs',
};

export const getUtagData = () => {
  if (typeof window === 'undefined') return {};
  return window.utag_data || {};
};

//#region track
export const trackOnPage = (utagObj = {}) => {
  if (typeof window === 'undefined' || !isObject(utagObj)) return;
  let utagData = getUtagData();
  if (Object.keys(utagData).length) {
    window.utag_data = {
      ...utagData,
      ...utagObj,
    };
  }
};

export const trackOnShare = (eventName, channel) => {
  eventLink('share', { share_channel: channel });
};

export const trackOnSearch = (eventName, keyword) => {
  let searchCategory = [''];
  if (eventName === eventNames.searchAvailablePrograms || eventName === eventNames.searchCompletedPrograms) {
    searchCategory = ['programs', 'contents'];
  }
  eventLink('onsite_search', { search_keyword: keyword, search_type: 'suggested', search_category: searchCategory });
};

export const trackOnSearchResult = (eventName, data) => {
  let searchCategory = [''];
  if (eventName === eventNames.searchAvailablePrograms || eventName === eventNames.searchCompletedPrograms) {
    searchCategory = ['programs', 'contents'];
  }
  eventView('onsite_search', {
    search_keyword: data.keyword,
    search_category: searchCategory,
    search_type: 'suggested',
    search_result: data.result,
  });
};

export const trackOnRegister = () => {
  eventLink('customer_registration_start');
};

export const trackOnError = (data) => {
  eventLink('error_event', {
    error_code: data.code,
    error_form: data.form, //service name
    error_message: data.message,
  });
};

//#region utag method
export const eventLink = (eventName, data = {}) => {
  if (typeof window === 'undefined' || !window?.utag || !isObject(data)) return;
  if (eventName) {
    data.event_name = eventName;
  }
  window.utag.link({
    ...data,
  });
};

export const eventView = (eventName, data = {}) => {
  if (typeof window === 'undefined' || !window?.utag || !isObject(data)) return;
  if (eventName) {
    data.event_name = eventName;
  }
  window.utag.view({
    ...data,
  });
};

//#region general func
export const getIMCID = (aboNumber = 0, aff = 0) => {
  return `${parseInt(aff) * 100000000000 + parseInt(aboNumber)}`;
};
