import React from 'react';
import notFoundImg from '../../assets/icon/svg/404.svg';
import { useSelector } from 'react-redux';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { getLang } from '../../utils/helper';
import { Button } from '../../app-components/Button';
import { useNotFound } from '../../hooks';

const NotFound = () => {
  const navigate = useNavigate();
  const setNotFound = useNotFound();
  const { data } = useSelector((state) => state.layout);
  let commonLabel = {};
  if (data) commonLabel = data?.commonLabel?.[0]?.labels;
  return (
    <div className="not-found">
      <img src={notFoundImg} alt="404 not found" />
      <h1>{commonLabel?.page_not_found_label}</h1>
      <p>{commonLabel?.page_not_found_description}</p>
      <Button
        label={commonLabel?.back_to_home_label}
        color="#32bfaf"
        onClick={() => {
          setNotFound(false);
          navigate(`/${getLang()}`);
        }}
      />
    </div>
  );
};

export default NotFound;
