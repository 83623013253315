import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import SignIn from '../pages/common/SignIn';
import Home from '../pages/community';
import Landing from '../pages/Landing';
// import Registration from '../pages/common/Registration';
// import Login from '../pages/common/Login';

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/sign-in" element={<SignIn />} /> */}
      <Route path="/" element={<Landing />}>
        {/* <Route path="/:lang/login" element={<Login />} />
        <Route path="/:lang/registration" element={<Registration />} /> */}
        <Route path="/:lang/*" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
