import React, { useState } from 'react';
import amwayLogo from '../../assets/img/Amw_Logo_Black.png';
import './index.scss';
import { Card, CardContent, Radio } from '@mui/material';
import { Footer } from '../../app-components';
import { Button } from '../../app-components/Button';

const AccountSelection = ({ accounts, login = () => {} }) => {
  const [selectedValue, setSelectedValue] = useState(0);

  const handleOnContinue = async () => {
    login({ acc: accounts?.[selectedValue] });
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <main className="acc-select">
      <header className="acc-select__header">
        <img src={amwayLogo} alt="amway-logo" />
      </header>
      <section className="acc-select__content">
        <Card className="acc-select__content-card">
          <div className="acc-select__content-card-header">
            <h1>Please select your profile:</h1>
            <p>You are linked to multiple accounts. Please choose the account you wish to operate with.</p>
          </div>
          <CardContent>
            {accounts.map((acc, idx) => (
              <div key={idx} className="acc-select__content-card-item" onClick={() => handleChange(idx)}>
                <Radio
                  className="acc-select__radio"
                  checked={selectedValue === idx}
                  value={idx}
                  onChange={() => handleChange(idx)}
                  inputProps={{ 'aria-label': `${acc?.abo_name} ${acc?.abo}` }}
                />
                <div>
                  <div className="name">{acc?.abo_name}</div>
                  <div className="aboId">{acc?.abo}</div>
                </div>
              </div>
            ))}
            <Button
              backgroundColor={'#d8284c'}
              width={'100%'}
              size={'medium'}
              label={'Continue'}
              onClick={() => handleOnContinue()}
              className="acc-select__content-btn"
            />
          </CardContent>
        </Card>
      </section>
      <Footer />
    </main>
  );
};

export default AccountSelection;
