import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import IconSVG from '../assets/icon/Icon';
import { getLang, pathStack } from '../utils/helper';

const Footer = () => {
  const { data } = useSelector((state) => state.layout);
  const location = useLocation();
  let fData = {};
  if (data) {
    fData = data.footer;
  }

  return (
    <footer className="footer">
      <div className="footer__breadcrumb-wrapper">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color={'inherit'} to="">
            <img src={fData?.logo?.url} alt={fData?.logo?.filename} />
          </Link>
        </Breadcrumbs>
      </div>
      <div className="footer__content">
        <div className="footer__list-wrapper">
          {fData?.left_hyperlink_block?.map((col, idx) => (
            <div className="footer__list" key={idx}>
              {col?.hyperlink_column?.hyperlink_row_block?.map((link, i) => {
                if (link?.external_link) {
                  let _obj = link?.external_link?.link;
                  return (
                    <p key={`${idx}-${i}`}>
                      <Link
                        to={_obj.href?.replace('{{lang}}', getLang())}
                        target={link?.external_link?.open_new_tab ? '_blank' : '_self'}
                        color={'inherit'}
                        className="footer__list-link"
                        state={{ pathStack: pathStack(location.state?.pathStack).push() }}
                      >
                        {_obj.title}
                      </Link>
                    </p>
                  );
                } else {
                  return (
                    <div key={`${idx}-${i}`} className="socmed">
                      {link?.social_link?.social_link_group?.map((socmed, idx1) => (
                        <Link
                          key={idx1}
                          to={socmed?.link?.href}
                          color={'inherit'}
                          className="footer__list-link"
                          title={socmed?.link?.title}
                        >
                          <IconSVG width={24} height={24} link={socmed?.image?.url} />
                        </Link>
                      ))}
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
        <div className="footer__logo">
          {fData?.right_hyperlink_block?.map((link, index) => {
            let obj = link.row?.right_hyperlink_column_block?.[0]?.image_with_link;
            return (
              <div key={index} className="footer__logo-wrapper">
                <Link to={obj?.link?.href} color={'inherit'} title={obj?.link?.title}>
                  <img src={obj?.image?.url} alt={obj?.image?.filename} />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer__copyright">
        <p>{fData?.copyright_text}</p>
      </div>
    </footer>
  );
};

export default Footer;
