import React from 'react';
import { CircularProgress } from '@mui/material';
import './index.scss';

export const PageLoading = () => {
  return (
    <div className="page-loading">
      <CircularProgress />
    </div>
  );
};
