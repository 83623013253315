import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sdk: undefined,
  lastFetch: undefined,
};

const sdkSlice = createSlice({
  name: 'amwaySdk',
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.sdk = action.payload.sdk;
      state.lastFetch = action.payload.lastFetch;
    },
  },
});

export default sdkSlice;
