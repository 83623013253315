import { useRef } from 'react';

export const useDebounce = (timeout = 700) => {
  const timer = useRef(null);
  return (func) => {
    return (...args) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  };
};
