import React from 'react';
import DOMPurify from 'dompurify';
import svg from './svg.json';

const IconSVG = ({ link, name, ...props }) => {
  if (link) {
    return (
      <svg {...props}>
        <image width={'100%'} height={'100%'} xlinkHref={link} />
      </svg>
    );
  } else {
    return <span {...props} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg.name[name]) }}></span>;
  }
};

export default IconSVG;
