import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  breakpoint: undefined,
  isDesktop: false,
  width: undefined,
};

const breakpointSlice = createSlice({
  name: 'breakpoint',
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.breakpoint = action.payload.breakpoint;
      state.isDesktop = action.payload.isDesktop;
      state.width = action.payload.width;
    },
  },
});

export default breakpointSlice;
