import React from 'react';
import DrawerForm from '../../components/Drawer/DrawerForm';
import { useSelector } from 'react-redux';

const SystemSupport = ({ isShow, logout }) => {
  const { data } = useSelector((state) => state.layout);
  let commonLabel = {};
  if (data) commonLabel = data?.commonLabel?.[0]?.labels;

  return (
    <DrawerForm
      open={isShow}
      title={commonLabel?.login_error_label}
      primaryButtonLabel={commonLabel?.ok_label}
      primaryButtonOnClick={logout}
      hideCloseButton={true}
    >
      <div style={{ textAlign: 'justify', padding: '0 20px', lineHeight: '32px' }}>
        {commonLabel?.login_error_description}
      </div>
    </DrawerForm>
  );
};

export default SystemSupport;
