import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSet: false,
  isLoggedIn: false,
  isLoggingOut: false,
  userInfo: {},
  token: {},
  role: {},
  errorMessage: '',
  loginError: false,
  refreshAppToken: false,
  refreshAmwayToken: false,
};

const authSlide = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setLogin(state, action) {
      state.isSet = true;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userInfo = action.payload.userInfo;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.errorMessage = action.payload.errorMessage;
      state.loginError = action.payload.error;
    },
    clearLogin(state, action) {
      state.isSet = true;
      state.isLoggedIn = false;
      state.isLoggingOut = action.payload.isLoggingOut;
      state.userInfo = {};
      state.token = {};
      state.role = {};
      state.errorMessage = '';
      state.loginError = false;
    },
    refreshToken(state, action) {
      state.refreshAppToken = action.payload.refreshAppToken;
      state.refreshAmwayToken = action.payload.refreshAmwayToken;
    },
  },
});

export default authSlide;
