import axios from '../utils/axios-interceptor';

const LayoutService = {
  async getAllLayout() {
    return await axios.get(`/public/layout`);
  },
  async getFaq() {
    return await axios.get(`/public/layout/faq`);
  },
  async getPrivacyPolicy() {
    return await axios.get(`/public/layout/privacyPolicy`);
  },
  async getTermsOfUse() {
    return await axios.get(`/public/layout/termsOfUse`);
  },
  async getTutorial() {
    return await axios.get(`/utils/tutorial`);
  },
  async updateTutorial(payload) {
    return await axios.post(`/utils/tutorial`, payload);
  },
};

export default LayoutService;
