import layoutSlice from '../reducers/layoutSlice';
import LayoutService from '../../services/layoutService';

const layoutAction = layoutSlice.actions;

export const fetchAllLayout = () => {
  return async (dispatch) => {
    const getData = await LayoutService.getAllLayout();
    dispatch(layoutAction.setData({ data: getData?.data?.result, lastUpdate: new Date().toISOString() }));
  };
};

export const setIsNotFound = (isNotFound) => {
  return (dispatch) => {
    dispatch(layoutAction.setIsNotFound({ isNotFound }));
  };
};
