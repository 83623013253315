import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import './utils/axios-interceptor';
import './styles/reduction.scss';
import { LoadingProvider } from './components/Loading';
import ErrorBoundary from './app-components/ErrorBoundary';
import { SnackbarProvider } from 'notistack';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const App = () => {
  return (
    <BrowserRouter basename={getBasename()}>
      <LoadingProvider>
        <SnackbarProvider
          autoHideDuration={2000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          classes={{ containerAnchorOriginTopRight: 'toast' }}
        >
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </SnackbarProvider>
      </LoadingProvider>
    </BrowserRouter>
  );
};

export default App;
