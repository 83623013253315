import axios from 'axios';
import { randomStr } from '../utils/helper';
import { useContext } from 'react';
import { LoadingContext } from '../components/Loading';

export const useAPICall = () => {
  const setLoadingStack = useContext(LoadingContext);

  let result;
  return async (apiFn, ...args) => {
    const id = randomStr(4);
    try {
      setLoadingStack?.((prev) => {
        return [...prev, id];
      });
      result = await apiFn(...args);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        result = {
          status: error.response?.status,
          data: error.response?.data,
        };
      } else {
        result = error;
      }
    } finally {
      setLoadingStack?.((prev) => {
        let arr = [...prev];
        let idx = arr.indexOf(id);
        if (idx !== -1) arr.splice(idx, 1);
        return arr;
      });
    }
    return result?.data;
  };
};
