import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
  newMessages: [],
  archievedMessages: [],
  lastRead: '',
};

const notificationSlide = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setMessage(state, action) {
      const newMessages = action.payload.filter((message) => {
        // Check if the message has a unique recId and status is 'NEW'
        return (
          !state.messages.some((existingMessage) => existingMessage.recId === message.recId) && message.status === 'NEW'
        );
      });

      // Sort new messages by createdDate in ascending order
      newMessages.sort((a, b) => new Date(a.createdDate || '').getTime() - new Date(b.createdDate || '').getTime());

      // Update state with new messages
      state.newMessages = newMessages;

      // Combine new messages with existing messages
      const allMessages = [...state.messages, ...newMessages];

      // Sort all messages by createdDate in descending order
      allMessages.sort((a, b) => new Date(b.createdDate || '').getTime() - new Date(a.createdDate || '').getTime());

      // Update state with sorted and unique messages
      state.messages = allMessages;

      // Set last read, so BE will only fetch new message
      state.lastRead = new Date().toISOString();
    },

    removeMessage(state, action) {
      const recIdToRemove = action.payload;
      state.messages = state.messages.filter((message) => message.recId !== recIdToRemove);
    },

    clearMessage(state) {
      state.messages = [];
      state.newMessages = [];
      state.lastRead = '';
    },
  },
});

export default notificationSlide;
