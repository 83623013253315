import React, { createContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import './index.scss';

export const LoadingContext = createContext(null);

export const Loading = () => {
  return (
    <div className="framework-loading">
      <CircularProgress />
    </div>
  );
};

export const LoadingProvider = ({ children }) => {
  const [loadingStack, setLoadingStack] = useState([]);
  useEffect(() => {
    if (loadingStack.length > 0) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [loadingStack]);
  return (
    <LoadingContext.Provider value={setLoadingStack}>
      {
        <div className={loadingStack.length > 0 ? undefined : 'framework-loading-hide'}>
          <Loading />
        </div>
      }
      {children}
    </LoadingContext.Provider>
  );
};
