import React from 'react';
import { Button as MuiButton } from '@mui/material';

export const Button = ({
  size = 'medium', //small, medium
  backgroundColor,
  width,
  label,
  icon,
  border,
  color = '#fff',
  buttonStyle, //icon
  disabled = false,
  minWidth,
  borderRadius = '100px',
  padding,
  ...props
}) => {
  let fontSize;
  switch (size) {
    case 'small':
      fontSize = '12px';
      break;
    case 'medium':
      fontSize = '14px';
      break;
    default:
      break;
  }
  return (
    <MuiButton
      type="button"
      className={'button'}
      style={{
        padding: padding ? padding : buttonStyle === 'icon' ? '6px' : '8px 16px',
        minWidth: buttonStyle === 'icon' ? 'unset' : minWidth ? minWidth : undefined,
        backgroundColor: disabled ? '#BABABA' : backgroundColor,
        width,
        fontSize,
        border,
        color: disabled ? '#FFFFFF' : color,
        cursor: disabled ? 'unset' : 'pointer',
        borderRadius: borderRadius,
      }}
      disabled={disabled}
      {...props}
    >
      {icon}
      {label}
    </MuiButton>
  );
};
