import axios from '../utils/axios-interceptor';

const NotificationService = {
  async getNotification() {
    return await axios.get(`/notification`);
  },
  async getNotificationUnreadCount() {
    return await axios.get(`/notification/getUnreadCount`);
  },
  async markNotificationAsRead(payload) {
    return await axios.post(`/notification/markAsRead`, payload);
  },
};

export default NotificationService;
