import headerSlice from '../reducers/headerSlice';
import NotificationService from '../../services/notificationService';

const headerAction = headerSlice.actions;

export const fetchNotificationCount = () => {
  return async (dispatch) => {
    const getData = await NotificationService.getNotificationUnreadCount();
    dispatch(headerAction.setShowNotificationBadge({ data: getData?.data?.result?.count || 0 }));
  };
};

export const setTutorial = ({ showDisplay, showIntro }) => {
  return async (dispatch) => {
    dispatch(
      headerAction.setTutorial({
        tutorial: {
          showDisplay,
          showIntro,
        },
      }),
    );
  };
};

export const setTutorialContent = ({ content }) => {
  return async (dispatch) => {
    dispatch(headerAction.setTutorialContent({ content }));
  };
};
