import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import breakpointSlice from '../../redux/reducers/breakpointSlice';

/** Constants **/
export const BREAKPOINT = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
};

const breakpointAction = breakpointSlice.actions;

const Breakpoint = () => {
  const dispatch = useDispatch();

  const getBreakpoint = (width) => {
    return width <= BREAKPOINT.xs
      ? 'xs'
      : width <= BREAKPOINT.sm
        ? 'sm'
        : width <= BREAKPOINT.md
          ? 'md'
          : width <= BREAKPOINT.lg
            ? 'lg'
            : 'xl';
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const newBreakpoint = getBreakpoint(width);
      dispatch(
        breakpointAction.setData({
          breakpoint: newBreakpoint,
          isDesktop: newBreakpoint !== 'xs' && newBreakpoint !== 'sm',
          width: width,
        }),
      );
    };
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Only re-run the effect if onBreakpointChange changes

  return null;
};

export default Breakpoint;
