import React, { useEffect, useRef, useState } from 'react';
import * as jose from 'jose';
import he from 'he';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { buildUrl, getLang, isTokenValid, validatePhone } from '../utils/helper';
import { LANGUAGES } from '../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearLogin, fetchLoginSession, setRefreshToken } from '../redux/actions/authAction';
import { fetchAllLayout } from '../redux/actions/layoutAction';
import AccountSelection from './common/AccountSelection';
import {
  getState,
  // getConfigByCountry,
  updateState,
  getAmwayLogoutUrl,
  removeState,
  getCountryByDomain,
  // getAmwayIdLoginUrl,
} from '../utils/login';
import { useAPICall, useNotFound } from '../hooks';
import AuthService from '../services/authService';
import { Loading } from '../components/Loading';
import { useSnackbar } from 'notistack';
import ProfileInfo from './community/profileInfo';
// import { setSdk } from '../redux/actions/amwaySdkAction';
import { fetchNotificationCount, setTutorial } from '../redux/actions/headerAction';
import SystemSupport from './common/SystemSupport';
import Tutorial from './community/tutorial';
import { eventView, getIMCID, getUtagData, trackOnError, trackOnPage } from '../utils/analytics';

const VALID_AFF = '100';
const VALID_COUNTRIES = ['MY', 'BN', 'SG'];

const Landing = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const apiCall = useAPICall();
  const setNotFound = useNotFound();
  const { enqueueSnackbar } = useSnackbar();
  const pageView = useRef();
  // const timeoutRef = useRef();

  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProfileInfoDrawer, setShowProfileInfoDrawer] = useState(false);
  const [showSystemSupport, setShowSystemSupport] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [showTermOfUse, setShowTermOfUse] = useState(false);

  const { isLoggedIn, userInfo, role, refreshAppToken, refreshAmwayToken } = useSelector((state) => state.auth);
  const { tutorial } = useSelector((state) => state.header);

  useEffect(() => {
    const lang = window.location.pathname?.split('/')?.[1];
    if (!(lang && LANGUAGES.includes(lang))) {
      window.location.replace(buildUrl('/', getLang()));
    } else {
      document.documentElement.setAttribute('lang', lang);
      dispatch(fetchAllLayout());
    }

    const country = getCountryByDomain(window.location.hostname);
    trackOnPage({
      site_country: country === 'BN' ? 'bn' : 'my',
      site_language: getLang(),
    });

    // initialize amwaysdk
    // timeoutRef.current = setTimeout(() => {
    //   // if (typeof window === 'undefined') return;
    //   if (typeof window !== 'undefined') {
    //     initAmwaySDK({ ...window });
    //   } else {
    //     enqueueSnackbar('Please contact administrator. Error: window is not defined', { variant: 'error' });
    //   }
    // }, [3000]);

    // return () => {
    //   clearTimeout(timeoutRef.current);
    // };
  }, []);

  useEffect(() => {
    checkRedirect();
    if (location.pathname.includes('/terms-and-conditions')) {
      setShowTermOfUse(true);
    } else {
      setShowTermOfUse(false);
    }

    pageView.current = setTimeout(() => {
      eventView(null, getUtagData());
    }, 1000);
    return () => {
      clearTimeout(pageView.current);
    };
  }, [location]);

  useEffect(() => {
    if (props.isNotFound) {
      setNotFound(true);
    }
  }, [props.isNotFound]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchNotificationCount());
      trackOnPage({
        visitor_imcID: getIMCID(userInfo.aboId, userInfo.affCode),
        visitor_partyID: userInfo.partyId,
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let userProfile = '';
    if (role.abo) {
      userProfile = 'abo';
    } else if (role.apc) {
      userProfile = 'member';
    } else if (role.public) {
      userProfile = 'product user';
    } else {
      userProfile = 'guest';
    }
    trackOnPage({
      visitor_userProfile: userProfile,
    });
  }, [role]);

  useEffect(() => {
    if (refreshAppToken) {
      (async () => {
        const appToken = localStorage.getItem('appToken');
        if (appToken) {
          const decodeAppToken = jose.decodeJwt(appToken);
          const { aboId, partyId } = decodeAppToken.account;
          dispatch(setRefreshToken({ appToken: false }));
          await loginSession({ aboId, partyId });
          navigate(0); //refresh
        }
      })();
    }
  }, [refreshAppToken]);

  useEffect(() => {
    (async () => {
      if (refreshAmwayToken) {
        const appToken = localStorage.getItem('appToken');
        const iToken = localStorage.getItem('idToken');
        if (appToken && iToken) {
          const decodeAppToken = jose.decodeJwt(appToken);
          const decodeIdToken = jose.decodeJwt(iToken);
          const { aboId } = decodeAppToken.account;
          dispatch(setRefreshToken({ amwayToken: false }));
          await login({ acc: decodeIdToken?.accounts?.find((acc) => acc.abo === aboId) });
        }
      }
    })();
  }, [refreshAmwayToken]);

  // const initAmwaySDK = async (windowObj) => {
  //   const { AmwayId } = windowObj || {};
  //   if (!AmwayId) {
  //     enqueueSnackbar('Please contact administrator.', { variant: 'error' });
  //   } else {
  //     const { client_app } = getConfigByCountry();
  //     // AmwayId.Enums.Environments.Local
  //     // AmwayId.Enums.Environments.Dev
  //     // AmwayId.Enums.Environments.Qa
  //     // AmwayId.Enums.Environments.Apacregqa
  //     // AmwayId.Enums.Environments.Prod
  //     const env =
  //       process.env.REACT_APP_ENV === 'PROD'
  //         ? AmwayId.Enums.Environments.Prod
  //         : process.env.REACT_APP_ENV === 'UAT'
  //           ? AmwayId.Enums.Environments.Qa
  //           : AmwayId.Enums.Environments.Local;
  //     // eslint-disable-next-line no-console
  //     console.log('env', env);
  //     const _sdk = await AmwayId.createSdkInstanceAsync(env, client_app);
  //     dispatch(setSdk(_sdk));
  //   }
  // };

  const checkRedirect = async () => {
    let aToken = localStorage.getItem('accessToken');
    let iToken = localStorage.getItem('idToken');
    const appToken = localStorage.getItem('appToken');
    let code = localStorage.getItem('code');
    const redirect = await getState('redirect_url');
    const tutorial = localStorage.getItem('tutorial');
    if (redirect) {
      await removeState('redirect_url');
      window.location.href = redirect;
      return;
    }
    if (code) {
      setIsLoading(true);
      code = he.decode(code);
      const res = await apiCall(AuthService.getRequestToken, { code });
      if (res.code !== 200) {
        enqueueSnackbar('Error occur. Please contact administrator.', { variant: 'error' });
        trackOnError({
          code: res.code,
          form: 'AuthService.getRequestToken',
          message: res.message || 'Please contact administrator.',
        });
      } else {
        aToken = res?.result?.access_token;
        iToken = res?.result?.id_token;
        localStorage.setItem('accessToken', aToken);
        localStorage.setItem('idToken', iToken);
        await updateState([['rt', res?.result?.refresh_token]]);
      }
      localStorage.removeItem('code');
    }
    if (aToken) {
      if (isTokenValid(aToken)) {
        setIsLoading(true);
        const decodeIdToken = jose.decodeJwt(iToken);
        let _accounts =
          decodeIdToken.accounts?.filter(
            (acc) =>
              VALID_COUNTRIES.includes(acc.country) &&
              acc.sales_plan_aff === VALID_AFF &&
              acc.status?.toUpperCase() === 'ACTIVE',
          ) || [];

        // no valid accounts
        if (_accounts.length === 0) {
          setIsLoading(false);
          setShowSystemSupport(true);
          return;
        }
        _accounts = _accounts.map((acc) => ({
          ...acc,
          email: decodeIdToken.account?.email ?? '',
          username: decodeIdToken.username ?? '',
          phone: decodeIdToken.username && validatePhone(decodeIdToken.username) ? decodeIdToken.username : null,
        }));
        // check appToken exist, if no, then proceed to creating login session
        if (appToken) {
          const decodeAppToken = jose.decodeJwt(appToken);
          const aboId = decodeAppToken?.account?.aboId;
          const selectedAcc = _accounts.find((acc) => acc.abo === aboId);
          // check selectedAccount (appToken) found in idToken, if no, then show error message
          if (selectedAcc) {
            const userInfo = {
              aboId: selectedAcc.abo,
              name: selectedAcc.abo_name,
              email: selectedAcc.email,
              affCode: selectedAcc.sales_plan_aff,
              status: selectedAcc.status,
              partyId: selectedAcc.lcl_partyid,
            };
            dispatch(fetchLoginSession({ aToken, iToken, userInfo, appToken }));
            setIsLoading(false);

            // start tutorial
            if (tutorial) {
              dispatch(setTutorial({ showDisplay: false, showIntro: true }));
            }
          } else {
            setIsLoading(false);
            setShowSystemSupport(true);
          }
        } else {
          setAccounts(_accounts);
          if (_accounts.length > 1) {
            // abo picker popup
            setIsLoading(false);
            return;
          } else {
            login({ acc: _accounts?.[0] });
          }
        }
      } else {
        const tk = jose.decodeJwt(aToken);
        // refresh token expired 1 day after access token expired date
        if (tk?.exp && tk?.aud && tk?.iat && new Date().getTime() < tk.exp * 1000 + 60 * 60 * 24 * 1000) {
          dispatch(setRefreshToken({ amwayToken: true }));
        } else {
          logout();
        }
      }
    } else {
      logout();
    }
  };

  const logout = (logoutUrl) => {
    dispatch(clearLogin(logoutUrl));
  };

  const login = async ({ acc, name, email }) => {
    const aToken = localStorage.getItem('accessToken');
    const tokenValid = isTokenValid(aToken);
    const aboId = acc.abo;
    const affCode = acc.sales_plan_aff;
    const partyId = acc.lcl_partyid;
    const status = acc.status;
    const acct_type = acc.acct_type;
    const acct_subtype = acc.acct_subtype;
    const phone = acc.phone?.replace('+', '');

    if (tokenValid) {
      const userExists = await checkIsUserExist({
        aboId,
        partyId,
      });
      // user not exist and not submit name and email
      if (!userExists && !name && !email) {
        setSelectedAcc(acc);
        setShowProfileInfoDrawer(true);
        setIsLoading(false);
        return;
      }
    }

    const refreshToken = await getState('rt');
    const res = await apiCall(AuthService.getRefreshToken, { refreshToken, aboId, affCode });
    if (res.code !== 200) {
      window.location.hostname === 'localhost' || window.location.hostname === '172.0.0.1'
        ? enqueueSnackbar('Error occur. Please contact administrator.', { variant: 'error' })
        : enqueueSnackbar('Your session has been expired.', { variant: 'error' });
      if (!tokenValid) {
        logout();
        return;
      }
    } else {
      const result = res?.result ?? {};
      //set new accessToken
      localStorage.setItem('accessToken', result.access_token);
      localStorage.setItem('idToken', result.id_token);
      await updateState([['rt', result.refresh_token]]);
    }
    await loginSession({ name, email, phone, aboId, partyId, status, acct_type, acct_subtype });
    navigate(0);
  };

  const loginSession = async (payload) => {
    const res1 = await apiCall(AuthService.loginSession, payload);
    if (res1.code !== 200) {
      enqueueSnackbar('Error occur. Please contact administrator.', { variant: 'error' });
      trackOnError({
        code: res1.code,
        form: 'AuthService.loginSession',
        message: res1.message || 'Please contact administrator.',
      });
    } else {
      const result = res1?.result ?? {};
      //set appToken
      // localStorage.setItem('aboId', aboId);
      localStorage.setItem('appToken', result.appToken);
      if (result.isTutorialCompleted === 0) {
        localStorage.setItem('tutorial', result.isTutorialCompleted);
      }
    }
  };

  const checkIsUserExist = async ({ aboId, partyId }) => {
    const res = await apiCall(AuthService.checkUserExists, { aboId, partyId });
    if (res.code) {
      if (res.code === 200 && res.result?.id) {
        return true;
      }
    } else {
      enqueueSnackbar('Error occur. Please contact administrator.', { variant: 'error' });
      trackOnError({
        code: res.code,
        form: 'AuthService.checkUserExists',
        message: res.message || 'Please contact administrator.',
      });
    }
    return false;
  };

  if (isLoading) return <Loading />;

  return (
    <>
      {accounts.length > 1 && !showTermOfUse ? <AccountSelection accounts={accounts} login={login} /> : <Outlet />}
      {!showTermOfUse && (
        <ProfileInfo account={selectedAcc} showProfileInfoDrawer={showProfileInfoDrawer} login={login} />
      )}
      {(tutorial.showIntro || tutorial.showDisplay) && <Tutorial />}
      <SystemSupport isShow={showSystemSupport} logout={() => logout(getAmwayLogoutUrl())} />
    </>
  );
};

export default Landing;
