import axios from '../utils/axios-interceptor';

const AuthService = {
  async getUsernameToken(username) {
    return await axios.get(`/public/getUsernameToken?username=${username}`);
  },
  async getCustEnrollmentDetails(payload) {
    return await axios.post(`/public/getCustEnrollmentDetails`, payload);
  },
  async getRequestToken(payload) {
    return await axios.post(`/public/requestToken`, payload);
  },
  async getRefreshToken(payload) {
    return await axios.post(`/public/refreshToken`, payload);
  },
  async loginSession(payload) {
    return await axios.post(`/auth/loginSession`, payload);
  },
  async checkUserExists(payload) {
    return await axios.post(`/auth/checkUserExists`, payload);
  },
};

export default AuthService;
