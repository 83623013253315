import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: undefined,
  lastUpdate: undefined,
  isNotFound: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload.data;
      state.lastUpdate = action.payload.lastUpdate;
    },

    clearData(state) {
      state.data = undefined;
      state.lastUpdate = undefined;
    },

    setIsNotFound(state, action) {
      state.isNotFound = action.payload.isNotFound;
    },
  },
});

export default layoutSlice;
