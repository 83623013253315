import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { buildUrl, getLang } from '../utils/helper';

const ErrorFallback = () => {
  return (
    <div className="error-fallback">
      <h2>Something went wrong!</h2>
      <button onClick={() => (window.location.href = buildUrl('/', getLang()))}>Back To Home</button>
    </div>
  );
};

const ErrorBoundaryComponent = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryComponent;
