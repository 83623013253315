import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLanguageOpen: false,
  isProfileOpen: false,
  isNotificationOpen: false,
  showNotificationBadge: false,
  unreadNotificationCount: 0,
  tutorial: {
    showDisplay: false,
    showIntro: false,
    content: {},
  },
};

const headerSlide = createSlice({
  name: 'header',
  initialState: initialState,
  reducers: {
    setLanguageOpen(state, action) {
      state.isLanguageOpen = action.payload;

      // Close other
      state.isNotificationOpen = false;
      state.isProfileOpen = false;
    },
    setNotificationOpen(state, action) {
      state.isNotificationOpen = action.payload;

      // Close other
      state.isLanguageOpen = false;
      state.isProfileOpen = false;
    },
    setProfileOpen(state, action) {
      state.isProfileOpen = action.payload;

      // Close other
      state.isNotificationOpen = false;
      state.isLanguageOpen = false;
    },
    setShowNotificationBadge(state, action) {
      state.unreadNotificationCount = action.payload.data;
      state.showNotificationBadge = !!action.payload.data;
    },
    setTutorial(state, action) {
      state.tutorial = {
        ...state.tutorial,
        ...action.payload.tutorial,
      };
    },
    setTutorialContent(state, action) {
      state.tutorial.content = action.payload.content;
    },
  },
});

export default headerSlide;
