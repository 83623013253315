import * as jose from 'jose';
import authSlide from '../reducers/authSlice';
import { loginSession, logoutSession } from '../../utils/login';

export const authActions = authSlide.actions;

export const fetchLoginSession = ({ aToken, iToken, userInfo, appToken }) => {
  return (dispatch) => {
    let aTokenDecoded = jose.decodeJwt(aToken);
    let appTokenDecoded = jose.decodeJwt(appToken);

    let updatedUserInfo = {
      ...userInfo,
      name: appTokenDecoded?.account?.name ?? userInfo?.name,
      email: appTokenDecoded?.account?.email,
      id: appTokenDecoded?.account?.id,
    };

    const roleByAcctType = (acctType) => {
      let _role = {
        abo: false,
        apc: false,
        public: false,
      };
      if (acctType === 'AmwayBusiness') {
        _role.abo = true;
      } else if (acctType === 'Member') {
        _role.apc = true;
      } else {
        _role.public = true;
      }
      return _role;
    };

    let authState = {
      isLoggedIn: true,
      userInfo: updatedUserInfo,
      token: { access: aToken, id: iToken },
      error: false,
      errorMessage: '',
      role: roleByAcctType(appTokenDecoded?.account?.acctType),
    };
    loginSession(aTokenDecoded);
    dispatch(authActions.setLogin(authState));
  };
};

export const clearLogin = (logoutUrl, isLoggingOut = false) => {
  return (dispatch) => {
    // clear localStorage
    logoutSession();
    dispatch(authActions.clearLogin({ isLoggingOut }));
    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  };
};

export const setRefreshToken = ({ appToken = false, amwayToken = false }) => {
  return (dispatch) => {
    dispatch(authActions.refreshToken({ refreshAppToken: appToken, refreshAmwayToken: amwayToken }));
  };
};
