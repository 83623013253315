import React from 'react';
import { Drawer } from '@mui/material';
import './Drawer.scss';
import { Button } from '../../app-components/Button';

const DrawerForm = ({
  open,
  title,
  // buttonLabel,
  // disabledButton = false,
  primaryButtonLabel,
  primaryButtonDisabled = false,
  primaryButtonSx = {
    backgroundColor: '#d8284c',
    color: '#fff',
  },
  primaryButtonOnClick = () => {},
  secondaryButtonLabel,
  secondaryButtonDisabled = false,
  secondaryButtonSx = {
    backgroundColor: '#fefefe',
    color: '#2c2c2c',
  },
  secondaryButtonOnClick = () => {},
  hideCloseButton = false,
  onCloseClickAway = true,
  buttonStack = true,
  onClose = () => {},
  children,
  className,
  ...props
}) => {
  const isIOS = () => {
    if (typeof window !== 'undefined') {
      if (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <Drawer
      className={`drawer-with-title ${className ? className : ''}`.trim()}
      classes={{ paper: isIOS() ? 'styles.paper_container_iOS' : 'styles.paper_container' }}
      open={open}
      anchor={'bottom'}
      onClose={onCloseClickAway ? onClose : undefined}
      {...props}
    >
      {(title || !hideCloseButton) && (
        <div className="drawer-title">
          <div>{title}</div>
          {!hideCloseButton && (
            <button className="drawer-burger-menu active" onClick={onClose}>
              <div className="bar top"></div>
              <div className="bar middle"></div>
              <div className="bar bottom"></div>
            </button>
          )}
        </div>
      )}

      <div className="divider" />
      <div className="drawer-content">
        {children}

        {(primaryButtonLabel || secondaryButtonLabel) && (
          <div
            className={`drawer-footer-button ${buttonStack ? 'drawer-footer-button--stack' : 'drawer-footer-button--unstack'}`}
          >
            {primaryButtonLabel && (
              <Button
                label={primaryButtonLabel}
                backgroundColor={primaryButtonSx.backgroundColor}
                color={primaryButtonSx.color}
                width="100%"
                size="medium"
                disabled={primaryButtonDisabled}
                onClick={primaryButtonOnClick}
              />
            )}
            {secondaryButtonLabel && (
              <Button
                label={secondaryButtonLabel}
                backgroundColor={secondaryButtonSx.backgroundColor}
                color={secondaryButtonSx.color}
                width="100%"
                size="medium"
                disabled={secondaryButtonDisabled}
                onClick={secondaryButtonOnClick}
              />
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerForm;
