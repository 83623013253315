import React, { useLayoutEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import DrawerForm from '../../../components/Drawer/DrawerForm';
import { useDispatch, useSelector } from 'react-redux';
import { setTutorial, setTutorialContent } from '../../../redux/actions/headerAction';
import './index.scss';
import LayoutService from '../../../services/layoutService';
import { useAPICall } from '../../../hooks';
import { useSnackbar } from 'notistack';

const Tutorial = () => {
  const dispatch = useDispatch();
  const { tutorial } = useSelector((state) => state.header);
  const { data } = useSelector((state) => state.layout);
  const { userInfo } = useSelector((state) => state.auth);
  const { isDesktop } = useSelector((state) => state.breakpoint);
  let commonLabel = {};
  if (data) commonLabel = data?.commonLabel?.[0]?.labels;
  const apiCall = useAPICall();
  const { enqueueSnackbar } = useSnackbar();
  const [content, setContent] = useState({});
  const [material, setMaterial] = useState([]);
  const [itemIdx, setItemIdx] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      let _content = { ...content };
      if (Object.keys(_content)?.length === 0) {
        const res = await apiCall(LayoutService.getTutorial);
        if (res?.code !== 200) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }
        _content = res?.result?.tutorial?.[0]?.content || [];
        _content = _content.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});
        setContent(_content);
        dispatch(setTutorialContent({ content: _content }));
      }
      setMaterial(_content?.[isDesktop ? 'desktop_view' : 'mobile_view']?.image || []);
    })();
  }, [isDesktop]);

  const handleGetStarted = () => {
    if (content?.mobile_view?.image?.length > 0 && content?.desktop_view?.image?.length > 0) {
      dispatch(setTutorial({ showDisplay: true, showIntro: false }));
      setIsLoading(true);
    } else {
      handleCloseTutorial();
    }
  };

  const handleTutorial = (mode) => {
    setIsLoading(true);
    setItemIdx((prev) => (mode === 'next' ? prev + 1 : prev - 1));
  };

  const handleCloseTutorial = async () => {
    const res = await apiCall(LayoutService.updateTutorial, { isTutorialCompleted: true });
    if (res?.code !== 200) {
      enqueueSnackbar(res.message, { variant: 'error' });
      return;
    }
    localStorage.removeItem('tutorial');
    dispatch(setTutorial({ showDisplay: false, showIntro: false }));
    setItemIdx(0);
  };

  return (
    <>
      {tutorial?.showIntro ? (
        <DrawerForm
          className={'tutorial-intro'}
          hideCloseButton={true}
          open={tutorial?.showIntro}
          primaryButtonLabel={commonLabel?.get_started_label}
          primaryButtonOnClick={handleGetStarted}
        >
          <div className="tutorial-intro__wrapper">
            <div className="tutorial-intro__head">
              <div>{commonLabel?.hey_user_title?.replace('{user-name}', userInfo?.name || '')}</div>
              <div>{commonLabel?.welcome_to_our_app_label}</div>
            </div>
            <p>{commonLabel?.get_started_description}</p>
          </div>
        </DrawerForm>
      ) : (
        <DrawerForm
          title={`${commonLabel?.tutorial_label} ${material.length > 0 ? `[${itemIdx + 1}/${material?.length}]` : ''}`}
          className={'tutorial-display'}
          open={tutorial?.showDisplay}
          primaryButtonLabel={material.length !== itemIdx + 1 ? commonLabel?.next_label : commonLabel?.end_label}
          primaryButtonOnClick={() => {
            if (material.length !== itemIdx + 1) {
              handleTutorial('next');
            } else {
              handleCloseTutorial();
            }
          }}
          secondaryButtonLabel={itemIdx !== 0 ? commonLabel?.previous_label : undefined}
          secondaryButtonOnClick={() => handleTutorial('previous')}
          onClose={handleCloseTutorial}
          onCloseClickAway={false}
          buttonStack={false}
        >
          <div className="tutorial-display__wrapper">
            {material?.[itemIdx]?.url ? (
              <img
                className="tutorial-display__image"
                src={material?.[itemIdx]?.url}
                alt={`tutorial-${itemIdx}`}
                loading="lazy"
                onLoad={() => setIsLoading(false)}
              />
            ) : (
              <div className="tutorial-display__lazy"></div>
            )}
            {isLoading && (
              <div className="tutorial-display__lazy loader">
                <CircularProgress />
              </div>
            )}
          </div>
        </DrawerForm>
      )}
    </>
  );
};

export default Tutorial;
