import React from 'react';
import { Footer, Header } from './index';
import Breakpoint from '../components/Breakpoint';
import { useSelector } from 'react-redux';
import NotFound from '../components/NotFound';

const MainLayout = ({ children }) => {
  const { isNotFound } = useSelector((state) => state.layout);
  return (
    <div id="cr-app" className="cr-app">
      <Breakpoint />
      <Header />
      <main className="cr-app__main-content">{isNotFound ? <NotFound /> : children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
