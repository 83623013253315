import { configureStore } from '@reduxjs/toolkit';
import authSlide from './reducers/authSlice';
import notificationSlide from './reducers/notificationSlice';
import headerSlide from './reducers/headerSlice';
import layoutSlice from './reducers/layoutSlice';
import breakpointSlice from './reducers/breakpointSlice';
import amwaySdkSlice from './reducers/amwaySdkSlice';

const store = configureStore({
  reducer: {
    auth: authSlide.reducer,
    header: headerSlide.reducer,
    notification: notificationSlide.reducer,
    layout: layoutSlice.reducer,
    breakpoint: breakpointSlice.reducer,
    amwaySdk: amwaySdkSlice.reducer,
  },
});

export default store;
