import axios from 'axios';
//const helper = require('./helper');
import { getAPIHost, getAccessToken, isTokenValid, getLang, getAppToken } from './helper';

axios.interceptors.request.use(async (request) => {
  const accessToken = getAccessToken();
  const appToken = getAppToken();
  const language = getLang();

  // Check if the request URL contains '/public/'
  if (request.url.includes('/public/')) {
    // Add the x-public-token header
    request.headers['x-public-token'] = 'Bearer Zw3DoMfUPihKUKFEp47oyo6Vk1Qg1C608sD32ZTDlDucH6h7ERWKBZatlEgquEwe';
  }

  if (accessToken && !request.url.includes('/public/')) {
    if (isTokenValid(accessToken)) {
      // with valid token time

      const headers = {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        'Accept-Language': language + '-MY',
        'x-app-token': appToken,
      };

      request.headers = {
        ...headers,
        ...request.headers,
      };

      /*request.data = {
        ...request.data,
        // for future customization
      };*/

      return request;
    }
  } else {
    // for other purpose, eg: login, log, parameters
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': language + '-MY',
    };

    request.headers = {
      ...headers,
      ...request.headers,
    };

    return request;
  }
}, undefined);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (failedResponse) => {
    if (failedResponse.response?.status === 401) {
      /*if (
        failedResponse.response.config.url.search('AdmSytemMessage') < 0 &&
        failedResponse.response.config.url.search('logout') < 0 &&
        failedResponse.response.config.url.search('UXTracking') < 0
      ) {
        //alert(
        //  'Unauthorized access/session killed due to multiple login, please login.',
        //);
        window.location.href = '/logout?exp=401';
      }*/

      throw new axios.Cancel();
    }
    return Promise.reject(failedResponse);
  },
);

const baseUrl = getAPIHost() ?? '';
axios.defaults.baseURL = baseUrl.indexOf('localhost') > -1 ? baseUrl : `${baseUrl}/`;
export default axios;
