//import * as ls from './loginSession';
import { getLang, randomStr } from './helper';

const {
  REACT_APP_AMWAYID_BASE_URL_GLOBAL,
  REACT_APP_AMWAYID_CLIENT_ID_GLOBAL,
  REACT_APP_AMWAYID_BASE_URL,
  REACT_APP_AMWAYID_CLIENT_APP_ID_MY,
  REACT_APP_AMWAYID_CLIENT_APP_ID_BN,
  REACT_APP_AMWAYID_CLIENT_ID_MY,
  REACT_APP_AMWAYID_CLIENT_ID_BN,
  REACT_APP_AMWAYID_REDIRECT_URI_MY,
  REACT_APP_AMWAYID_REDIRECT_URI_BN,
  REACT_APP_AMWAYID_CANCEL_REDIRECT_URI_MY,
  REACT_APP_AMWAYID_CANCEL_REDIRECT_URI_BN,
} = process.env;

//========================================================================//

export const loginSession = (decoded) => {
  if (typeof window !== 'undefined') {
    // localStorage.setItem('aboId', decoded?.account?.abo);
    localStorage.setItem('globalPartyId', decoded?.gbl_partyid);
  } else {
    // eslint-disable-next-line no-console
    console.log('Login session valid on browser session only');
  }
};

export const logoutSession = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('appToken');
    localStorage.removeItem('state');
    // localStorage.removeItem('aboId');
    localStorage.removeItem('globalPartyId');
    localStorage.removeItem('tutorial');
  } else {
    // eslint-disable-next-line no-console
    console.log('Login session valid on browser session only');
  }
};

export const getScope = () => {
  return 'openid email profile abo abo_name accounts_details_profile offline_access account_profile bonus:all:read mdms:accounts:read';
};

export const getAmwayIdRegistrationUrl = (useGlobal = false) => {
  const REGISTRATION_URL =
    (useGlobal ? REACT_APP_AMWAYID_BASE_URL_GLOBAL : REACT_APP_AMWAYID_BASE_URL) + '/account/create-account';
  const { redirect_uri, cancel_redirect, client_app } = getConfigByCountry(useGlobal);
  const lang = 'en-us';
  return `${REGISTRATION_URL}?clientapp=${client_app}&cancelRedirect=${cancel_redirect}&redirect=${redirect_uri}&lng=${lang}`;
};

export const getAmwayIdLoginUrl = (stateObj, useGlobal = false) => {
  const RESPONSE_MODE = 'form_post';
  const RESPONSE_TYPE = 'code';
  const SCOPE = getScope();
  const NONCE = randomStr(12);
  const AUTHORIZATION_URL =
    (useGlobal ? REACT_APP_AMWAYID_BASE_URL_GLOBAL : REACT_APP_AMWAYID_BASE_URL) + '/v1/proxy/oxauth/restv1/authorize';
  const { redirect_uri, cancel_redirect, client_id, client_app } = getConfigByCountry(useGlobal);

  const locale = `en-us`;
  const state = { ...stateObj, amw_lng: getLang(), amw_clientapp: client_app };

  return `${AUTHORIZATION_URL}?response_mode=${RESPONSE_MODE}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&nonce=${NONCE}&state=${btoa(JSON.stringify(state))}&redirect_uri=${redirect_uri}&cancelredirect=${cancel_redirect}&client_id=${client_id}&amw_clientapp=${client_app}&amw_lng=${locale}`;
};

export const getAmwayLogoutUrl = (useGlobal = false) => {
  const LOGOUT_URL = (useGlobal ? REACT_APP_AMWAYID_BASE_URL_GLOBAL : REACT_APP_AMWAYID_BASE_URL) + '/signout';
  const { cancel_redirect, client_id, client_app } = getConfigByCountry(useGlobal);
  return `${LOGOUT_URL}?clientapp=${client_app}&client_id=${client_id}&redirect=${cancel_redirect}`;
};

export const getCountryByDomain = (url) => {
  const parts = url.split('.');
  return parts[parts.length - 1]?.toUpperCase();
};

export const getConfigByCountry = (useGlobal = false) => {
  let redirect_uri = '';
  let cancel_redirect = '';
  let client_id = '';
  let client_app = '';

  let countryDomain = null;
  if (window.location.hostname !== 'localhost' && window.location.hostname !== '172.0.0.1') {
    countryDomain = getCountryByDomain(window.location.hostname);
  }
  switch (countryDomain) {
    case 'MY':
      redirect_uri = REACT_APP_AMWAYID_REDIRECT_URI_MY;
      cancel_redirect = REACT_APP_AMWAYID_CANCEL_REDIRECT_URI_MY;
      client_id = useGlobal ? REACT_APP_AMWAYID_CLIENT_ID_GLOBAL : REACT_APP_AMWAYID_CLIENT_ID_MY;
      client_app = REACT_APP_AMWAYID_CLIENT_APP_ID_MY;
      break;
    case 'BN':
      redirect_uri = REACT_APP_AMWAYID_REDIRECT_URI_BN;
      cancel_redirect = REACT_APP_AMWAYID_CANCEL_REDIRECT_URI_BN;
      client_id = useGlobal ? REACT_APP_AMWAYID_CLIENT_ID_GLOBAL : REACT_APP_AMWAYID_CLIENT_ID_BN;
      client_app = REACT_APP_AMWAYID_CLIENT_APP_ID_BN;
      break;
    default:
      redirect_uri = REACT_APP_AMWAYID_REDIRECT_URI_MY;
      cancel_redirect = REACT_APP_AMWAYID_CANCEL_REDIRECT_URI_MY;
      client_id = useGlobal ? REACT_APP_AMWAYID_CLIENT_ID_GLOBAL : REACT_APP_AMWAYID_CLIENT_ID_MY;
      client_app = REACT_APP_AMWAYID_CLIENT_APP_ID_MY;
      break;
  }

  return { redirect_uri, cancel_redirect, client_id, client_app };
};

/**
 * Get state
 * @param {string | string[]} keys - Field key
 */
export const getState = async (keys) => {
  let state = localStorage.getItem('state');
  state = state ? await JSON.parse(atob(state)) : {};
  return typeof keys === 'string' ? state[keys] : keys.map((key) => state[key]);
};

/**
 * Update state
 * @param {[string, any][]} fields - Fields in state to be update
 */
export const updateState = async (fields) => {
  let state = localStorage.getItem('state');
  state = state ? await JSON.parse(atob(state)) : {};
  fields.forEach((field) => {
    state[field[0]] = field[1];
  });
  localStorage.setItem('state', btoa(JSON.stringify(state)));
};

/**
 * Remove state
 * @param {string | string[]} keys - Field key
 */
export const removeState = async (keys) => {
  let state = localStorage.getItem('state');
  state = state ? await JSON.parse(atob(state)) : {};
  if (state) {
    if (typeof keys === 'string') {
      delete state[keys];
    } else {
      keys.forEach((key) => delete state[key]);
    }
  }
  localStorage.setItem('state', btoa(JSON.stringify(state)));
};
