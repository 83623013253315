import React from 'react';
import { Drawer } from '@mui/material';
import './Drawer.scss';

const DrawerComponent = ({ open, anchor, onClose = () => {}, children, ...props }) => {
  const isIOS = () => {
    if (typeof window !== 'undefined') {
      if (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <Drawer
      className="drawer"
      {...props}
      classes={{ paper: isIOS() ? 'styles.paper_container_iOS' : 'styles.paper_container' }}
      open={open}
      anchor={anchor}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
